@import '@/styles/mixins.less';
@import "@/styles/common-class.less";

html,
body {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: white;
  .normalize();
  margin: 0;
  padding: 0;
}

#root {
  .size(100%);
}
