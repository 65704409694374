html,
body {
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
}

body {
  .normalize();
}


.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.row {
    &-1 {
      flex: 1;
    }

    &-2 {
      flex: 2;
    }

    &-3 {
      flex: 3;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  >.col {
    &-1 {
      flex: 1;
    }

    &-2 {
      flex: 2;
    }

    &-3 {
      flex: 3;
    }

    &-4 {
      flex: 4;
    }
  }
}

.flex-row {
  &.align-center {
    justify-content: center;
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &.align-side {
    justify-content: space-between;
  }

  &.align-round {
    justify-content: space-around;
  }

  &.align-even {
    justify-content: space-evenly;
  }

  &.justify-center {
    align-items: center;
  }

  &.justify-end {
    align-items: flex-end;
  }

  &.justify-start {
    align-items: flex-start;
  }

  &.justify-baseline {
    align-items: baseline;
  }
}

.flex-col {
  &.align-center {
    align-items: center;
  }

  &.align-left {
    align-items: flex-start;
  }

  &.align-right {
    align-items: flex-end;
  }

  &.align-side {
    align-items: space-between;
  }

  &.align-even {
    align-items: space-evenly;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-start {
    justify-items: flex-start;
  }

  &.justify-side {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }
}

.hide {
  display: none !important;
}


.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  background: #f3f3f3;
}


.clickable {
  cursor: pointer;
  user-select: none;
}


html {
  *[data-modal-active="true"] {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden !important;
  }
}

body {
  &[data-modal-active="true"] & {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}

/**
* 空状态修改新加
*/
.col-1 {
  flex: 1;
}